export const phoneScreens: string[] = [
  require("./assets/LaunchToken.mp4"),
  require("./assets/ManageVaults.mp4"),
  require("./assets/discover-vault.png"),
] as string[];

export const landingStickers: string[] = [
  require("./components/LandingHero/assets/sticker01.png"),
  require("./components/LandingHero/assets/sticker02.png"),
  require("./components/LandingHero/assets/sticker03.png"),
  require("./components/LandingHero/assets/sticker04.png"),
  require("./components/LandingHero/assets/sticker05.png"),
  require("./components/LandingHero/assets/sticker06.png"),
  require("./components/LandingHero/assets/sticker07.png"),
  require("./components/LandingHero/assets/sticker08.png"),
  require("./components/LandingHero/assets/sticker09.png"),
];
