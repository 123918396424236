import { useEffect } from "react";
import { LandingView } from "./screens/LandingView";
// import { Web3ContextProvider } from "libs/web3-react/components/Web3React";

function App() {
  useEffect(() => {
    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`, {
    //     scope: "/",
    //   });
    // }
  }, []);

  return (
    // <Web3ContextProvider>
      <LandingView />
    // </Web3ContextProvider>
  );
}

export default App;
